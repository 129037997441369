import type {
	Account,
	Companion,
	Conversation,
	DateCompanion,
	GallerySettings,
	GroupChat,
	GroupChatMessage,
	Message,
	Meta,
	Profile
} from '$components/utils';
import type { Session } from '@supabase/supabase-js';
import { persisted } from 'svelte-local-storage-store';
import { writable, type Writable } from 'svelte/store';

export const session: Writable<Session> = writable();

export const account: Writable<Account> = writable();

export const profile: Writable<Profile> = writable();

export const companions: Writable<Companion[]> = writable();
export const groupChats: Writable<GroupChat[]> = writable([]);

export const dateCompanions: Writable<DateCompanion[]> = writable([]);

export const conversations: Writable<Conversation[]> = writable();
export const dateConversations: Writable<Conversation[]> = writable([]);

export const managedDates: Writable<DateCompanion[]> = writable([]);

/* Saved conversation states */
export const conversationMessages: Writable<{ [conversationId: string]: Message[] }> = writable({});
export const groupChatMessages: Writable<{ [conversationId: string]: GroupChatMessage[] }> =
	writable({});
export const conversationSetting: Writable<{
	[conversationId: string]: { llm: string; autoMessages: boolean };
}> = persisted('conv-settings', {});

/* Meta */
export const meta: Writable<Meta> = writable();

/* App session state stores */
export const windowWidth: Writable<number> = writable();

export const isDating = persisted('date', false, {
	storage: 'session'
});

export const selectedConversationId = persisted('conversation-id', '', {
	storage: 'session'
});

export const selectedCompanionId = persisted('companion-id', '', {
	storage: 'session'
});

export const photoGallerySettings: Writable<GallerySettings> = persisted('gallery-settings', {
	prompt: '',
	n_iter: 1,
	steps: 25,
	negative_prompt: '',
	seed: -1,
	sampler_name: '',
	nsfw: true,
	model: 'realistic',
	disposition: 'portrait',
	cfg_scale: 7,
	pose_reference: '',
	face_reference: ''
});

export const isPhotoGalleryExpanded: Writable<boolean> = writable(false);

export const tab: Writable<'gallery' | 'favorite'> = writable('gallery');

export const cachedImages: Writable<{
	liked: any[];
	'not liked': any[];
	all: any[];
}> = writable({
	liked: [],
	'not liked': [],
	all: []
});

export const habitData: Writable<{
	profileId?: string;
	lastFetchedAt?: string;
	data?: any;
}> = persisted('habitData', { profileId: '', lastFetchedAt: '', data: [] });

export const isAudioAutoplaying = persisted('voice-autoplay', true);
export const isMessageInOneBlock = persisted('msg-one-block', true);
export const isAutoMessaging = persisted('auto-msg-groups', true);

export const isCompanionHeaderReduced = persisted('companion-header-reduced', false);

/* Events for modals */
export const isTokenBuyModalVisible: Writable<boolean> = writable(false);
export const isCreateCompanionModalVisible: Writable<boolean> = writable(false);
export const isCreateGroupChatModalVisible: Writable<boolean> = writable(false);
export const isCreateDateModalVisible: Writable<boolean> = writable(false);
export const isHelpModalVisible: Writable<boolean> = writable(false);
export const isVoiceFreeVisible: Writable<boolean> = writable(false);
export const isMemoriesVisible: Writable<boolean> = writable(false);

/* Mobile page states */
export const isNavVisible: Writable<boolean> = persisted('in-mobile-nav', false);
export const isMobileFullScreenModal: Writable<boolean> = persisted('in-mobile-modal-fs', false);
export const isActiveDatingPage: Writable<'explore' | 'date' | 'manage'> = persisted(
	'date-tab',
	'explore'
);
